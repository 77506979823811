import React, { useCallback, useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import QuoteEdit from './QuoteEdit';
import QuoteView from './QuoteView';

function Quote() {
  const [quotes, setQuotes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [editingQuote, setEditingQuote] = useState(null);
  const [creatingQuote, setCreatingQuote] = useState(false);
  const { t } = useTranslation('config');

  const fetchQuotes = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales/quotes`);
      const formattedQuotes = response.data.map(quote => ({
        ...quote,
        bridge: quote.number_of_bridges,
        businessPartner: {
          email: quote.businessPartnerEmail,
          name: quote.businessPartnerName
        },
        businessPartnerID: quote.businessParnetId,
        creationDate: new Date(quote.created_at).toLocaleString(),
        id: quote.quote_id,
        modificationDate: new Date(quote.updated_at).toLocaleString(),
        name: quote.nameQuoteCustomer,
        numberOfAccessPoints: quote.number_of_access_points,
        numberOfUsers: quote.number_of_users,
        planName: quote.plan_id,
        price: `$${quote.total_price}`,
        pricePerUser: quote.price_per_user,
        propertyName: quote.nameQuoteCustomer,
        status: quote.status,
        totalPrice: quote.total_price,
        users: quote.number_of_users
      }));
      setQuotes(formattedQuotes);
    } catch (error) {
      console.error(t('quote.errorFetchingQuotes'), error);
    }
  }, [t]);

  useEffect(() => {
    fetchQuotes();
  }, [fetchQuotes]);

  useEffect(() => {
    if (searchTerm === '') {
      setSearchFilter('');
    }
  }, [searchTerm]);

  const filteredQuotes = quotes.filter(quote => (quote.name?.toLowerCase() || '').includes(searchFilter.toLowerCase()));

  const handleViewClick = quote => {
    setSelectedQuote(quote);
  };

  const handleEditClick = quote => {
    setEditingQuote(quote);
  };

  const handleBackToTable = () => {
    setSelectedQuote(null);
    setEditingQuote(null);
    setCreatingQuote(false);
  };

  const handleSaveAndBack = async () => {
    await fetchQuotes();
    setEditingQuote(null);
    setSelectedQuote(null);
  };

  return (
    <Grid container padding={3} spacing={3}>
      {creatingQuote ? (
        <Grid item xs={12}>
          <Button color="primary" onClick={handleBackToTable} style={{ marginBottom: '20px' }} variant="outlined">
            {t('quote.back')}
          </Button>
          <QuoteEdit onCancel={handleBackToTable} onSave={handleSaveAndBack} quote={{}} />
        </Grid>
      ) : editingQuote ? (
        <Grid item xs={12}>
          <Button color="primary" onClick={handleBackToTable} style={{ marginBottom: '20px' }} variant="outlined">
            {t('quote.back')}
          </Button>
          <QuoteEdit onCancel={handleBackToTable} onSave={handleSaveAndBack} quote={editingQuote} />
        </Grid>
      ) : selectedQuote ? (
        <Grid item xs={12}>
          <Button color="primary" onClick={handleBackToTable} style={{ marginBottom: '20px' }} variant="outlined">
            {t('quote.back')}
          </Button>
          <QuoteView onEdit={handleEditClick} onSave={handleSaveAndBack} quote={selectedQuote} />
        </Grid>
      ) : (
        <>
          <Grid alignItems="center" container item justifyContent="space-between" spacing={2} xs={12}>
            <Grid container item justifyContent="flex-end" spacing={2} xs={12}>
              <Grid item md={3} xs={9}>
                <TextField
                  fullWidth
                  label={t('quote.propertyName')}
                  onChange={e => setSearchTerm(e.target.value)}
                  size="small"
                  value={searchTerm}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={2} xs={3}>
                <Button color="primary" fullWidth onClick={() => setSearchFilter(searchTerm)} variant="contained">
                  {t('quote.search')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('quote.name')}</TableCell>
                    <TableCell>{t('quote.users')}</TableCell>
                    <TableCell>{t('quote.totalPrice')}</TableCell>
                    <TableCell>{t('quote.status')}</TableCell>
                    <TableCell>{t('quote.creationDate')}</TableCell>
                    <TableCell>{t('quote.modificationDate')}</TableCell>
                    <TableCell>{t('quote.actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredQuotes.length > 0 ? (
                    filteredQuotes.map((quote, index) => (
                      <TableRow key={index}>
                        <TableCell>{quote.name}</TableCell>
                        <TableCell>{quote.users}</TableCell>
                        <TableCell>{quote.price}</TableCell>
                        <TableCell>{quote.status}</TableCell>
                        <TableCell>{quote.creationDate}</TableCell>
                        <TableCell>{quote.modificationDate}</TableCell>
                        <TableCell>
                          <IconButton color="primary" onClick={() => handleViewClick(quote)} size="small">
                            <VisibilityIcon />
                          </IconButton>
                          {quote.status !== 'sent' && (
                            <IconButton color="secondary" onClick={() => handleEditClick(quote)} size="small">
                              <EditIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        {t('quote.noQuotes')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Quote;
