// Teporarily hardcode group ids
export const EP_USR = [
  { id: 'bd5fc24b-8022-4b75-b970-db6192695ac9', key: 'SUE' },
  { id: '227a1af8-ead1-4db1-9ab9-746986a331d2', key: 'SUA' },
  { id: 'b3ccfd25-f7b2-412a-a74e-b2a102a12647', key: 'CME' },
  { id: '688fdacd-3a00-4bb0-bef6-957d339f4346', key: 'REE' },
  { id: '31523a85-135c-44ea-a29a-7adf6605b90b', key: 'BPE' },
  { id: '70d16a4e-f22a-4310-ae49-8f2d40ec8f22', key: 'TSE' },
  { id: '677116ec-4a00-455d-8dd5-2f79a0cd6748', key: 'EPM' },
  { id: 'fc9f01a4-3ea8-4a77-8ffa-4d64a02596e8', key: 'DUE' }
];

export const MENU_OPTIONS = {
  BPE: [
    {
      label: 'reports',
      url: '/reports'
    },
    {
      label: 'profile',
      url: '/profile'
    }
  ],
  CME: [
    {
      label: 'globalConfig',
      url: '/global-config'
    },
    {
      label: 'commerceManagement',
      //SalesTracking
      subPages: [
        {
          label: 'salesTracking',
          url: '/commerce-management/sales-tracking'
        }
      ],
      url: '/commerce-management'
    },
    {
      label: 'inventoryManagement',
      url: '/inventory-management'
    },
    {
      label: 'reports',
      url: '/reports'
    },
    {
      label: 'integrationManagement',
      url: '/integrations'
    },
    {
      label: 'profile',
      url: '/profile'
    }
  ],
  DUE: [
    {
      label: 'profile',
      url: '/profile'
    }
  ],
  EPM: [
    {
      label: 'profile',
      url: '/profile'
    }
  ],
  REE: [
    {
      label: 'globalConfig',
      url: '/global-config'
    },
    {
      label: 'inventoryManagement',
      url: '/inventory-management'
    },
    {
      label: 'reports',
      url: '/reports'
    },
    {
      label: 'profile',
      url: '/profile'
    }
  ],
  SUA: [
    {
      label: 'globalConfig',
      url: '/global-config'
    },
    {
      label: 'commerceManagement',
      //SalesTracking
      subPages: [
        {
          label: 'salesTracking',
          url: '/commerce-management/sales-tracking'
        }
      ],
      url: '/commerce-management'
    },
    {
      label: 'inventoryManagement',
      url: '/inventory-management'
    },
    {
      label: 'reports',
      url: '/reports'
    },
    {
      label: 'integrationManagement',
      url: '/integrations'
    },
    {
      label: 'profile',
      url: '/profile'
    }
  ],
  SUE: [
    {
      label: 'globalConfig',
      url: '/global-config'
    },
    {
      label: 'commerceManagement',
      //SalesTracking
      subPages: [
        {
          label: 'salesTracking',
          url: '/commerce-management/sales-tracking'
        }
      ],
      url: '/commerce-management'
    },
    {
      label: 'inventoryManagement',
      url: '/inventory-management'
    },
    {
      label: 'reports',
      url: '/reports'
    },
    {
      label: 'integrationManagement',
      url: '/integrations'
    },
    {
      label: 'profile',
      url: '/profile'
    }
  ],
  TSE: [
    {
      label: 'profile',
      url: '/profile'
    }
  ]
};
