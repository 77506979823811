import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

import InactivityHandler from './components/InactivityHandler';
import { ThemeProvider } from './context/ThemeProvider';
import Router from './pages/router';
import Unauthorized from './pages/unauthorized';

import 'react-toastify/dist/ReactToastify.css'; // Importar estilos de Toastify

export default function App() {
  const defaultTimeout = 300000; // 5 minutos

  return (
    <ThemeProvider>
      <CssBaseline />
      {/* Enable routing if user is authenticated */}
      <AuthenticatedTemplate>
        <InactivityHandler defaultTimeout={defaultTimeout}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </InactivityHandler>
      </AuthenticatedTemplate>
      {/* Block all interaction and redirect to login */}
      <UnauthenticatedTemplate>
        <Unauthorized />
      </UnauthenticatedTemplate>
    </ThemeProvider>
  );
}
