/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
/* eslint-disable sort-keys */
import React, { useEffect, useState } from 'react';
import { Edit, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import axios from 'axios';
import { format, set } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import CustomerForm from 'src/components/ClientsCRUD';
import QuoteEdit from 'src/components/Quote/QuoteEdit';
import QuoteView from 'src/components/Quote/QuoteView';

const commerce = () => {
  const { t } = useTranslation('registry');
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedClientDetails, setSelectedClientDetails] = useState(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [isQuoteEditModalOpen, setIsQuoteEditModalOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [isQuoteViewModalOpen, setIsQuoteViewModalOpen] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);

  const openQuoteEditModal = clientId => {
    setSelectedCustomerId(clientId);
    setIsQuoteEditModalOpen(true);
  };

  const theme = useTheme(); // Usar el tema actual para aplicar estilos dinámicamente

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales/customers`);
      console.log(response);

      setClients(response.data);
      setFilteredClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const fetchClientDetails = async clientId => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales/customer/${clientId}`);
      setSelectedClientDetails(response.data);
    } catch (error) {
      console.error('Error fetching client details:', error);
      setSelectedClientDetails(null);
    }
  };

  const fetchSomeQuote = async clientId => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales/quote/last/${clientId}`);
      return response; // Devuelve la respuesta para su uso en `handleViewDetails`
    } catch (error) {
      console.error('Error fetching some quote:', error);
      return null; // Manejo de errores básico
    }
  };

  const handleViewQuote = async clientId => {
    try {
      const response = await fetchSomeQuote(clientId);
      if (response?.data?.length > 0) {
        const quote = response.data[0];

        // Formatea los datos de la cotización
        const formattedQuote = {
          id: quote.id,
          propertyName: quote.nameQuoteCustomer,
          businessPartner: {
            name: quote.businessPartnerName,
            email: quote.businessPartnerEmail
          },
          planName: quote.plan_id,
          numberOfAccessPoints: quote.number_of_access_points,
          numberOfUsers: quote.number_of_users,
          bridge: quote.number_of_bridges,
          pricePerUser: quote.price_per_user,
          totalPrice: quote.total_price,
          status: quote.status
        };

        setSelectedQuote(formattedQuote);
        setIsQuoteViewModalOpen(true);
      } else {
        console.warn('No hay cotización disponible para este cliente.');
      }
    } catch (error) {
      console.error('Error al obtener la cotización:', error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleSearch = e => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    if (value) {
      const filtered = clients.filter(
        client =>
          client.name.toLowerCase().includes(value) ||
          client.bc_email.toLowerCase().includes(value) ||
          `${client.bc_first_name} ${client.bc_last_name}`.toLowerCase().includes(value)
      );
      setFilteredClients(filtered);
    } else {
      setFilteredClients(clients);
    }
  };

  const handleViewDetails = async client => {
    try {
      // Primero verifica si existe una cotización para el cliente
      const response = await fetchSomeQuote(client.customer_id);
      if (response?.data?.length > 0) {
        console.log('Cotización encontrada:', response.data[0]);
        console.log(response.data[0].status === 'sent');
        if (response.data[0].status === 'sent') {
          setIsTrue(false);
        } else {
          setIsTrue(true);
        }
        await fetchClientDetails(client.customer_id);
        setIsDetailModalOpen(true);
      } else {
        setIsTrue(false);
        await fetchClientDetails(client.customer_id);
        setIsDetailModalOpen(true);
      }
    } catch (error) {
      console.error('Error al verificar cotizaciones:', error);
    }
  };

  const handleEditClient = async client => {
    await fetchClientDetails(client.customer_id);
    setIsFormModalOpen(true);
  };

  const handleNewClient = () => {
    setSelectedClientDetails(null);
    setIsFormModalOpen(true);
  };

  const formatDate = date => (date ? format(new Date(date), 'dd/MM/yyyy HH:mm') : t('commerce.modal.noData'));

  return (
    <>
      <Box style={{ display: 'flex', gap: 20, justifyContent: 'end', marginBottom: 15 }}>
        <Button color="primary" onClick={handleNewClient} variant="contained">
          {t('commerce.addClient')}
        </Button>
        <TextField
          onChange={handleSearch}
          placeholder={t('commerce.searchPlaceholder')}
          style={{ maxWidth: 300 }}
          value={searchText}
          variant="outlined"
        />
      </Box>
      <TableContainer
        component={Paper}
        style={{
          margin: 'auto',
          marginTop: 20,
          maxWidth: 1200,
          backgroundColor: theme.palette.background.paper, // Adaptar al modo oscuro
          color: theme.palette.text.primary // Texto adaptable
        }}
      >
        <Table aria-label="Client Table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>{t('commerce.clientName')}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>{t('commerce.businessEmail')}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>{t('commerce.businessContact')}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>{t('commerce.creationDate')}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>{t('commerce.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(showAll ? filteredClients : filteredClients.slice(0, 15)).map((client, index) => (
              <TableRow key={index}>
                <TableCell>{client.name}</TableCell>
                <TableCell>{client.bc_email}</TableCell>
                <TableCell>{`${client.bc_first_name} ${client.bc_last_name}`}</TableCell>
                <TableCell>{formatDate(client.created_at)}</TableCell>
                <TableCell>
                  <Box style={{ display: 'flex', gap: '10px' }}>
                    <Visibility onClick={() => handleViewDetails(client)} style={{ cursor: 'pointer' }} />
                    <Edit onClick={() => handleEditClient(client)} style={{ cursor: 'pointer' }} />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!showAll && filteredClients.length > 15 && (
          <Button color="primary" style={{ display: 'block', margin: '20px auto' }} variant="contained">
            {t('commerce.showAll')}
          </Button>
        )}
      </TableContainer>

      <Modal onClose={() => setIsDetailModalOpen(false)} open={isDetailModalOpen}>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 800,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[5],
            padding: 20,
            borderRadius: 8
          }}
        >
          {selectedClientDetails && (
            <>
              <Typography style={{ fontWeight: 'bold', marginBottom: 10 }} variant="h5">
                {selectedClientDetails.name}
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                <strong>{t('commerce.modal.category')}:</strong> {selectedClientDetails.category || t('commerce.modal.noData')}
              </Typography>
              <Box style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}>
                <Box>
                  <Typography>
                    <strong>{t('commerce.modal.businessContactDetails')}:</strong>
                  </Typography>
                  <Typography>{`${selectedClientDetails.bc_first_name} ${selectedClientDetails.bc_last_name}`}</Typography>
                  <Typography>{selectedClientDetails.bc_email}</Typography>

                  <Typography>
                    <strong>{t('commerce.modal.operationalContact')}:</strong>
                  </Typography>
                  <Typography>{selectedClientDetails.oc_first_name || t('commerce.modal.noData')}</Typography>
                  <Typography>{selectedClientDetails.oc_email || t('commerce.modal.noData')}</Typography>

                  <Typography>
                    <strong>{t('commerce.modal.businessPartner')}:</strong>
                  </Typography>
                  <Typography>{t('commerce.modal.partnerName')}</Typography>
                  <Typography>{t('commerce.modal.partnerEmail')}</Typography>

                  <Typography>
                    <strong>{t('commerce.modal.creationDate')}:</strong> {formatDate(selectedClientDetails.created_at)}
                  </Typography>
                  <Typography>
                    <strong>{t('commerce.modal.modificationDate')}:</strong> {formatDate(selectedClientDetails.updated_at)}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    <strong>{t('commerce.modal.address')}:</strong>
                  </Typography>
                  {selectedClientDetails.address ? (
                    <>
                      <Typography>{selectedClientDetails.address.address_line_1}</Typography>
                      <Typography>{selectedClientDetails.address.city}</Typography>
                      <Typography>{selectedClientDetails.address.postal_code}</Typography>
                    </>
                  ) : (
                    <Typography>{t('commerce.modal.noData')}</Typography>
                  )}
                  <Typography>
                    <strong>{t('commerce.modal.taxInfo')}:</strong>
                  </Typography>
                  {selectedClientDetails.tax_info ? (
                    <>
                      <Typography>{selectedClientDetails.tax_info.tax_name}</Typography>
                      <Typography>{selectedClientDetails.tax_info.rfc}</Typography>
                      <Typography>{selectedClientDetails.tax_info.postal_code}</Typography>
                    </>
                  ) : (
                    <Typography>{t('commerce.modal.noData')}</Typography>
                  )}
                  <Typography>
                    <strong>{t('Quote')}:</strong>
                  </Typography>
                  {isTrue ? (
                    // esto por lo mientras lo dejare asi mas adelante lo ocupare bastante
                    <Link
                      onClick={() => handleViewQuote(selectedClientDetails.customer_id)}
                      style={{ display: 'block', marginTop: 20, cursor: 'pointer' }}
                    >
                      {t('commerce.buttons.link')}
                    </Link>
                  ) : (
                    <Button
                      color="primary"
                      onClick={() => openQuoteEditModal(selectedClientDetails.customer_id)} // Abre el modal y pasa el ID del cliente
                      variant="contained"
                    >
                      {t('commerce.buttons.newQuote')}
                    </Button>
                  )}
                </Box>
              </Box>
              <Box style={{ display: 'flex', gap: 20 }}>
                <Button color="primary" onClick={() => setIsDetailModalOpen(false)} style={{ marginTop: 20 }} variant="outlined">
                  {t('commerce.modal.close')}
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setIsDetailModalOpen(false);
                    setIsFormModalOpen(true);
                  }}
                  style={{ marginTop: 20 }}
                  variant="contained"
                >
                  {t('commerce.modal.edit')}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
      {/* Modal para Formulario prospecto */}
      <Modal onClose={() => setIsFormModalOpen(false)} open={isFormModalOpen}>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 900,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[5],
            padding: 20,
            borderRadius: 8
          }}
        >
          <CustomerForm
            id={selectedClientDetails?.customer_id || null}
            initialValues={selectedClientDetails}
            isEditMode={!!selectedClientDetails}
            onClose={() => setIsFormModalOpen(false)}
          />
        </Box>
      </Modal>
      {/* Modal para crear una cotizaciones */}
      <Modal
        aria-describedby="quote-edit-modal-description"
        aria-labelledby="quote-edit-modal"
        onClose={() => setIsQuoteEditModalOpen(false)} // Cierra el modal al hacer clic fuera
        open={isQuoteEditModalOpen}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '900px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2
          }}
        >
          <QuoteEdit
            onClose={() => setIsQuoteEditModalOpen(false)} // Cierra el modal directamente
            onSave={updatedQuote => {
              console.log('Cotización actualizada:', updatedQuote);
              setIsQuoteEditModalOpen(false); // Cierra el modal de edición
              setIsDetailModalOpen(false); // También cierra el modal de detalles
            }}
            quote={{ customer_id: selectedCustomerId, customer: selectedClientDetails }}
          />
        </Box>
      </Modal>
      {/* Modal para ver una Quote ya creada desde prospecto */}
      <Modal
        aria-describedby="quote-view-modal-description"
        aria-labelledby="quote-view-modal"
        onClose={() => setIsQuoteViewModalOpen(false)}
        open={isQuoteViewModalOpen}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '900px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2
          }}
        >
          <QuoteView
            disableEdit={true} // Esto desactivará el botón de edición
            onEdit={updatedQuote => console.log('Editar cotización:', updatedQuote)}
            onSave={() => {
              console.log('Cotización guardada');
              setIsQuoteViewModalOpen(false);
            }}
            quote={selectedQuote}
          />
        </Box>
      </Modal>
    </>
  );
};

export default commerce;
