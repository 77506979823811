import { memo } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import AcceptTermsAndConditions from 'src/components/AcceptTermsAndConditions';
import { TopMenu } from 'src/components/Navigation';
import RegistryComponent from 'src/components/pages/RegistryComponent';

function ActivationLayout({ handleAcceptTermsButtonClick }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar disableGutters>
          <TopMenu />
        </Toolbar>
      </AppBar>
      <Box component="main" minHeight="100vh">
        <Toolbar />
        {handleAcceptTermsButtonClick ? (
          <AcceptTermsAndConditions handleAcceptTermsButtonClick={handleAcceptTermsButtonClick} />
        ) : (
          <RegistryComponent />
        )}
      </Box>
    </Box>
  );
}

export default memo(ActivationLayout);
