import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Trans, useTranslation } from 'react-i18next';

const FullScreenImage = styled(Box)({
  alignItems: 'center',
  backgroundImage: 'url("/images/registry/registry.svg")',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-end',
  left: 0,
  padding: '20px',
  position: 'fixed',
  top: 0,
  width: '100%'
});

const TermsContainer = styled(Box)({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '8px',
  color: '#fff',
  maxWidth: '500px',
  padding: '20px',
  textAlign: 'center',
  width: '100%'
});

const AcceptTermsAndConditions = ({ handleAcceptTermsButtonClick }) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation('global');

  const handleCheckboxChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <FullScreenImage>
      <TermsContainer>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
          label={
            <Typography>
              <Trans i18nKey="acceptTermsAndConditions">
                I accept the
                <Link href={t('termsAndConditionsLink')} target="_blank">
                  Terms and Conditions
                </Link>
                of service.
              </Trans>
            </Typography>
          }
        />
        <Button color="primary" disabled={!checked} onClick={handleAcceptTermsButtonClick} variant="contained">
          {t('accept')}
        </Button>
      </TermsContainer>
    </FullScreenImage>
  );
};

export default AcceptTermsAndConditions;
