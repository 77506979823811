/* eslint-disable sort-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

function CustomerForm({ id, isEditMode, initialValues, onClose }) {
  const { t } = useTranslation('registry');
  const [activeStep, setActiveStep] = useState(0);
  const [isSameAsGeneralInfo, setIsSameAsGeneralInfo] = useState(false);

  const steps = [t('commerce.steps.generalInfo'), t('commerce.steps.fiscalInfo'), t('commerce.steps.contactInfo')];
  const successMessage = t('commerce.toast.success');
  const successEditMessage = t('commerce.toast.edited');
  const errorMessage = t('commerce.toast.error');

  const cfdiUseOptions = [
    { label: 'Gastos en general', value: 'G01' },
    { label: 'Adquisición de bienes', value: 'G02' },
    { label: 'Construcciones', value: 'G03' },
    { label: 'Honorarios médicos', value: 'D01' },
    { label: 'Gastos funerales', value: 'D02' },
    { label: 'Donativos', value: 'D03' },
    { label: 'Intereses reales', value: 'D04' },
    { label: 'Aportaciones voluntarias', value: 'D05' },
    { label: 'Primas por seguros', value: 'D06' },
    { label: 'Gastos educativos', value: 'D07' },
    { label: 'Otros', value: 'P01' }
  ];

  const taxRegimeOptions = [
    { label: 'Régimen Simplificado de Confianza (RESICO)', value: 626 },
    { label: 'Régimen General de Personas Morales', value: 601 },
    { label: 'Personas Físicas con Actividades Empresariales', value: 612 },
    { label: 'Régimen de Incorporación Fiscal (RIF)', value: 621 },
    { label: 'Régimen General de Ley de Personas Morales con Fines no Lucrativos', value: 603 },
    { label: 'Regímenes Fiscales Especiales', value: 622 }
  ];

  const fetchCp = async id_cp => {
    if (!id_cp) return;
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/cp/${id_cp}`);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching postal code details:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: initialValues?.name || '',
      address: {
        address_line_1: initialValues?.address?.address_line_1 || '',
        address_line_2: initialValues?.address?.address_line_2 || '',
        neighborhood: initialValues?.address?.neighborhood || '',
        city: initialValues?.address?.city || '',
        country: initialValues?.address?.country || 'MEX',
        postal_code: initialValues?.address?.postal_code || '',
        country_code: initialValues?.address?.country_code || 'MEX'
      },
      tax_info: {
        rfc: initialValues?.tax_info?.rfc || '',
        tax_name: initialValues?.tax_info?.tax_name || '',
        tax_regime: initialValues?.tax_info?.tax_regime ? parseInt(initialValues.tax_info.tax_regime, 10) : '',
        postal_code: initialValues?.tax_info?.postal_code || '',
        cfdi_use_code: initialValues?.tax_info?.cfdi_use_code || ''
      },
      bc_first_name: initialValues?.bc_first_name || '',
      bc_last_name: initialValues?.bc_last_name || '',
      bc_email: initialValues?.bc_email || '',
      bc_is_oc: initialValues?.bc_is_oc || false,
      oc_first_name: initialValues?.oc_first_name || '',
      oc_last_name: initialValues?.oc_last_name || '',
      oc_email: initialValues?.oc_email || '',
      category: initialValues?.category || '',
      business_partner_id: initialValues?.business_partner_id || '232bfb09-0dc2-4a04-a921-b879f8158b8e' //por el momento esta hardcodeado porque no existen mas socios de negocios
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().max(100).required(t('commerce.registrationForm.required')),
      address: Yup.object({
        address_line_1: Yup.string().max(100).required(t('commerce.registrationForm.required')),
        neighborhood: Yup.string().max(45).required(t('commerce.registrationForm.required')),
        city: Yup.string().max(45).required(t('commerce.registrationForm.required')),
        postal_code: Yup.string().max(5).required(t('commerce.registrationForm.required'))
      }),
      tax_info: Yup.object({
        rfc: Yup.string().max(14).required(t('commerce.registrationForm.required')),
        tax_name: Yup.string().max(100).required(t('commerce.registrationForm.required')),
        tax_regime: Yup.number().required(t('commerce.registrationForm.required')),
        cfdi_use_code: Yup.string().required(t('commerce.registrationForm.required'))
      }),
      bc_first_name: Yup.string().max(45).required(t('commerce.registrationForm.required')),
      bc_last_name: Yup.string().max(45).required(t('commerce.registrationForm.required')),
      bc_email: Yup.string().email().max(100).required(t('commerce.registrationForm.required'))
    }),
    onSubmit: handleSubmit
  });

  async function handleSubmit(values) {
    try {
      const formattedValues = {
        ...values,
        tax_info: {
          ...values.tax_info,
          tax_regime: values.tax_info.tax_regime.toString()
        }
      };
      if (isEditMode) {
        await axios.patch(`${process.env.REACT_APP_API_URL}/sales/customer/${id}`, formattedValues);
        toast.success(successEditMessage);
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/sales/customer`, formattedValues);
        toast.success(successMessage);
      }
      onClose();
    } catch (error) {
      console.error('Error enviando datos:', error);
      toast.error(errorMessage);
    }
  }

  function handleCheckboxChange(e, formik) {
    const isChecked = e.target.checked;
    formik.setFieldValue('bc_is_oc', isChecked);

    if (isChecked) {
      formik.setFieldValue('oc_first_name', formik.values.bc_first_name);
      formik.setFieldValue('oc_last_name', formik.values.bc_last_name);
      formik.setFieldValue('oc_email', formik.values.bc_email);
    } else {
      formik.setFieldValue('oc_first_name', '');
      formik.setFieldValue('oc_last_name', '');
      formik.setFieldValue('oc_email', '');
    }
  }

  useEffect(() => {
    if (formik.values?.address?.postal_code) {
      fetchCp(formik.values.address.postal_code);
    }
  }, [formik.values?.address?.postal_code]);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4">{isEditMode ? t('title2') : t('title')}</Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form onSubmit={formik.handleSubmit}>
        {activeStep === 0 && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('commerce.registrationForm.name')}
                {...formik.getFieldProps('name')}
                error={Boolean(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('commerce.registrationForm.addressLine1')}
                {...formik.getFieldProps('address.address_line_1')}
                error={Boolean(formik.touched.address?.address_line_1 && formik.errors.address?.address_line_1)}
                helperText={formik.touched.address?.address_line_1 && formik.errors.address?.address_line_1}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label={t('commerce.registrationForm.addressLine2')} {...formik.getFieldProps('address.address_line_2')} />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={t('commerce.registrationForm.neighborhood')}
                {...formik.getFieldProps('address.neighborhood')}
                error={Boolean(formik.touched.address?.neighborhood && formik.errors.address?.neighborhood)}
                helperText={formik.touched.address?.neighborhood && formik.errors.address?.neighborhood}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={t('commerce.registrationForm.city')}
                {...formik.getFieldProps('address.city')}
                error={Boolean(formik.touched.address?.city && formik.errors.address?.city)}
                helperText={formik.touched.address?.city && formik.errors.address?.city}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={t('commerce.registrationForm.postalCode')}
                {...formik.getFieldProps('address.postal_code')}
                error={Boolean(formik.touched.address?.postal_code && formik.errors.address?.postal_code)}
                helperText={formik.touched.address?.postal_code && formik.errors.address?.postal_code}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                displayEmpty
                fullWidth
                onChange={e => {
                  formik.setFieldValue('address.country', 'México');
                  formik.setFieldValue('address.country_code', 'MEX');
                }}
                value={formik.values.address.country_code}
              >
                <MenuItem disabled value="">
                  {t('commerce.registrationForm.selectCountry')}
                </MenuItem>
                <MenuItem value="MEX">México</MenuItem>
              </Select>
            </Grid>
          </Grid>
        )}
        {activeStep === 1 && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('commerce.registrationForm.rfc')}
                {...formik.getFieldProps('tax_info.rfc')}
                error={Boolean(formik.touched.tax_info?.rfc && formik.errors.tax_info?.rfc)}
                helperText={formik.touched.tax_info?.rfc && formik.errors.tax_info?.rfc}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('commerce.registrationForm.taxName')}
                {...formik.getFieldProps('tax_info.tax_name')}
                error={Boolean(formik.touched.tax_info?.tax_name && formik.errors.tax_info?.tax_name)}
                helperText={formik.touched.tax_info?.tax_name && formik.errors.tax_info?.tax_name}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                displayEmpty
                fullWidth
                onChange={e => formik.setFieldValue('tax_info.tax_regime', e.target.value)}
                value={formik.values.tax_info.tax_regime}
              >
                <MenuItem disabled value="">
                  {t('commerce.registrationForm.taxRegime')}
                </MenuItem>
                {taxRegimeOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label={t('commerce.registrationForm.taxPostalCode')} {...formik.getFieldProps('tax_info.postal_code')} />
            </Grid>
            <Grid item xs={6}>
              <Select
                displayEmpty
                fullWidth
                onChange={e => formik.setFieldValue('tax_info.cfdi_use_code', e.target.value)}
                value={formik.values.tax_info.cfdi_use_code}
              >
                <MenuItem disabled value="">
                  {t('commerce.registrationForm.cfdiUse')}
                </MenuItem>
                {cfdiUseOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}
        {activeStep === 2 && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('commerce.registrationForm.bcName')}
                {...formik.getFieldProps('bc_first_name')}
                error={Boolean(formik.touched.bc_first_name && formik.errors.bc_first_name)}
                helperText={formik.touched.bc_first_name && formik.errors.bc_first_name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('commerce.registrationForm.bcLastName')}
                {...formik.getFieldProps('bc_last_name')}
                error={Boolean(formik.touched.bc_last_name && formik.errors.bc_last_name)}
                helperText={formik.touched.bc_last_name && formik.errors.bc_last_name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t('commerce.registrationForm.bcEmail')}
                {...formik.getFieldProps('bc_email')}
                error={Boolean(formik.touched.bc_email && formik.errors.bc_email)}
                helperText={formik.touched.bc_email && formik.errors.bc_email}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={formik.values.bc_is_oc} onChange={e => handleCheckboxChange(e, formik)} />}
                label={t('commerce.registrationForm.sameAsGeneralInfo')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label={t('commerce.registrationForm.ocName')} {...formik.getFieldProps('oc_first_name')} />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label={t('commerce.registrationForm.ocLastName')} {...formik.getFieldProps('oc_last_name')} />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label={t('commerce.registrationForm.ocEmail')} {...formik.getFieldProps('oc_email')} />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label={t('commerce.registrationForm.category')} {...formik.getFieldProps('category')} />
            </Grid>
          </Grid>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          {activeStep === 0 ? (
            <Button onClick={onClose} variant="outlined">
              {t('commerce.buttons.close')}
            </Button>
          ) : (
            <Button disabled={activeStep === 0} onClick={() => setActiveStep(prev => prev - 1)} variant="outlined">
              {t('commerce.buttons.previous')}
            </Button>
          )}
          {activeStep < steps.length - 1 ? (
            <Button onClick={() => setActiveStep(prev => prev + 1)} type="button" variant="contained">
              {t('commerce.buttons.next')}
            </Button>
          ) : (
            <Button onClick={() => handleSubmit(formik.values)} type="button" variant="contained">
              {isEditMode ? t('commerce.buttons.update') : t('commerce.buttons.create')}
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
}

export default CustomerForm;
