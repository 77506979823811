import React, { useState } from 'react';
import { Box, Button, Modal, Tab, Tabs, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

const LicensesInfo = ({ licensesData }) => {
  const [cup, setCup] = useState();
  const [details, setDetails] = useState();
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('activation');

  const handleClick = async () => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/sales/license/${cup}/activate`);
    } catch (error) {
      console.log('error activating license:', error);
    }
  };

  const handleClose = () => setOpen(false);
  const handleOpen = async () => {
    try {
      const getLicenseResponse = await axios.get(`${process.env.REACT_APP_API_URL}/sales/license/${licensesData?.[0]?.license_id}`);
      if (getLicenseResponse.data) {
        setDetails(getLicenseResponse.data);
      }
      console.log('getLicenseResponse', getLicenseResponse);
    } catch (error) {
      console.log('error getting license info:', error);
    }
    setOpen(true);
  };
  const handleTabChange = (event, newValue) => setTabValue(newValue);

  return (
    <Box
      sx={{
        width: isMobile ? '100%' : '33.33%'
      }}
    >
      <Typography sx={{ marginBottom: '15px' }} variant="h5">
        {t('activeLicenses')}
      </Typography>
      {!licensesData?.length ? (
        <>
          <Typography>{t('noActiveLicensesFound')}</Typography>
          <TextField
            fullWidth
            label="CUP-XXXX-XXXX-XXXX"
            margin="normal"
            onChange={event => {
              setCup(event.target.value);
            }}
            variant="outlined"
          />
          <Button color="primary" fullWidth onClick={handleClick} variant="contained">
            {t('activateNewLicense')}
          </Button>
        </>
      ) : (
        <>
          {/* <Typography sx={{ marginTop: '10px' }} variant="h6">
            {licensesData[0]?.customerName}
          </Typography> */}
          <TextField disabled fullWidth label={licensesData[0]?.cup} variant="outlined" />
          <Typography sx={{ marginTop: '10px' }}>
            {t('validUntil', { expiryDate: format(parseISO(licensesData[0]?.expiry_date), 'dd/MM/yyyy') })}
          </Typography>
          <Button onClick={handleOpen} variant="text">
            {t('viewDetails')}
          </Button>
        </>
      )}
      <Modal aria-describedby="modal-description" aria-labelledby="modal-title" onClose={handleClose} open={open}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            left: '50%',
            p: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800
          }}
        >
          <Typography component="h2" id="modal-title" variant="h6">
            {t('details')}
          </Typography>
          <Tabs aria-label="basic tabs example" onChange={handleTabChange} value={tabValue}>
            <Tab label={t('general')} />
            <Tab label={t('taxInfo')} />
            <Tab label={t('adminInfo')} />
            <Tab label={t('licenseInfo')} />
            <Tab label={t('businessPartnerInfo')} />
          </Tabs>
          {details && (
            <Box sx={{ mt: 2 }}>
              {tabValue === 0 &&
                Object.entries(details?.customer_info)?.map(([key, value]) => (
                  <Box key={key} sx={{ marginBottom: 1 }}>
                    {/* <Typography variant="subtitle1" color="textSecondary">
                    {key}:
                  </Typography> */}
                    <Typography variant="body1">{typeof value === 'object' ? JSON.stringify(value, null, 2) : value}</Typography>
                  </Box>
                ))}
              {tabValue === 1 &&
                Object.entries(details?.billing_info)?.map(([key, value]) => (
                  <Box key={key} sx={{ marginBottom: 1 }}>
                    {/* <Typography variant="subtitle1" color="textSecondary">
                    {key}:
                  </Typography> */}
                    <Typography variant="body1">{typeof value === 'object' ? JSON.stringify(value, null, 2) : value}</Typography>
                  </Box>
                ))}
              {tabValue === 2 &&
                Object.entries(details?.admin_info)?.map(([key, value]) => (
                  <Box key={key} sx={{ marginBottom: 1 }}>
                    {/* <Typography variant="subtitle1" color="textSecondary">
                    {key}:
                  </Typography> */}
                    <Typography variant="body1">{typeof value === 'object' ? JSON.stringify(value, null, 2) : value}</Typography>
                  </Box>
                ))}
              {tabValue === 3 && (
                <>
                  <Box sx={{ marginBottom: 1 }}>
                    <Typography color="textSecondary" variant="subtitle1">
                      {t('activationDate')}
                    </Typography>
                    <Typography variant="body1">{format(parseISO(details?.license_info?.activation_date), 'dd/MM/yyyy')}</Typography>
                  </Box>
                  <Box sx={{ marginBottom: 1 }}>
                    <Typography color="textSecondary" variant="subtitle1">
                      {t('expiryDate')}
                    </Typography>
                    <Typography variant="body1">{format(parseISO(details?.license_info?.expiry_date), 'dd/MM/yyyy')}</Typography>
                  </Box>
                </>
              )}
              {tabValue === 4 && (
                <Box sx={{ marginBottom: 1 }}>
                  <img src={details?.business_partner_info?.logo_url} style={{ maxWidth: '300px' }} />
                  <Typography variant="body1">{details?.business_partner_info?.name}</Typography>
                </Box>
              )}
            </Box>
          )}
          <Button color="secondary" onClick={handleClose} sx={{ mt: 2 }} variant="contained">
            {t('close')}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default LicensesInfo;
