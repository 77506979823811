import { memo, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import ActivationLayout from 'src/components/layouts/ActivationLayout';
import DefaultLayout from 'src/components/layouts/DefaultLayout';
import { LicensesInfo } from 'src/components/License';
import { getUserType, hasPropertyManagerAcceptedTerms, isBusinessPartnerStatusPending } from 'src/lib/util/authorization';

// import { loginRequest } from 'src/authConfig';

// const ProfileContent = () => {
//   const { instance, accounts } = useMsal();
//   const requestProfileData = () => {
//     // Silently acquires an access token which is then attached to a request for MS Graph data
//     instance
//       .acquireTokenSilent({
//         ...loginRequest,
//         account: accounts[0]
//       })
//       .then(response => {
//         console.log('token response:', response);
//         // callMsGraph(response.accessToken).then(response => setGraphData(response));
//       });
//   };
// };

const Dashboard = () => {
  const { accounts } = useMsal();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [licensesData, setLicensesData] = useState();
  const userType = getUserType(accounts);

  console.log('licensesData', licensesData);

  useEffect(() => {
    const getLicenseData = async () => {
      try {
        const userInfoResponse = await axios.get(`${process.env.REACT_APP_API_URL}/user/${accounts[0].idTokenClaims.sub}`);
        const licensesInfoResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/sales/licenses?customerId=${userInfoResponse.data?.customerId}`
        );
        setLicensesData(licensesInfoResponse.data);
      } catch (error) {
        // To do: change API to send empty array instead of 404
        if (error?.response?.status === 404) {
          setLicensesData([]);
        }
        console.log('error getting license data:', error);
      }
    };

    if (userType === 'EPM' && hasPropertyManagerAcceptedTerms(accounts) && !licensesData) {
      getLicenseData();
    }
  }, [accounts, licensesData, userType]);

  if (isBusinessPartnerStatusPending(accounts)) {
    return <ActivationLayout />;
  }

  if (userType === 'EPM' && !acceptedTerms && !hasPropertyManagerAcceptedTerms(accounts)) {
    const handleAcceptTermsButtonClick = async () => {
      try {
        localStorage.setItem('acceptedTerms', true);
        setAcceptedTerms(true);
        // Send API request to acknowledge T&C acceptance
        await axios.get(`${process.env.REACT_APP_API_URL}/user/${accounts[0].idTokenClaims.sub}/acceptTermsAndConditions`);
      } catch (error) {
        console.error('handleAcceptTermsButtonClick error:', error);
      }
    };

    return <ActivationLayout handleAcceptTermsButtonClick={handleAcceptTermsButtonClick} />;
  }

  return (
    <DefaultLayout title="dashboard">
      {userType === 'EPM' && hasPropertyManagerAcceptedTerms(accounts) ? (
        <LicensesInfo licensesData={licensesData} setLicensesData={setLicensesData} />
      ) : (
        <Typography sx={{ marginBottom: 2 }}>Dashboard content (TBD)</Typography>
      )}
      {/* <ProfileContent /> */}
    </DefaultLayout>
  );
};

export default memo(Dashboard);
