import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClientTable from 'src/components/ClientsTable';
import DefaultLayout from 'src/components/layouts/DefaultLayout';
import QuoteComponent from 'src/components/Quote/Quote';

function SalesTracking() {
  const { t } = useTranslation('config');
  const [value, setValue] = useState('Cotizaciones');
  const [shouldUpdateQuotes, setShouldUpdateQuotes] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 'Cotizaciones') {
      setShouldUpdateQuotes(true); // Indica que las cotizaciones deben actualizarse
    }
  };

  const handleQuotesUpdated = () => {
    setShouldUpdateQuotes(false); // Resetea el indicador después de actualizar
  };

  return (
    <DefaultLayout>
      <Box sx={{ padding: 2 }}>
        <Typography gutterBottom variant="h4">
          {t('salesTracking.title')}
        </Typography>

        {/* Tabs para navegar entre Cotizaciones, Prospectos y Clientes */}
        <Tabs aria-label="Sales tracking tabs" indicatorColor="secondary" onChange={handleChange} textColor="secondary" value={value}>
          <Tab label={t('salesTracking.quote')} value="Cotizaciones" />
          <Tab label={t('salesTracking.prospect')} value="Prospectos" />
          <Tab label={t('salesTracking.client')} value="Clientes" />
        </Tabs>

        <Box sx={{ padding: 3 }}>
          {/* Contenido dinámico basado en la pestaña seleccionada */}
          {value === 'Cotizaciones' && <QuoteComponent onUpdated={handleQuotesUpdated} shouldUpdate={shouldUpdateQuotes} />}
          {value === 'Prospectos' && <ClientTable />}
          {value === 'Clientes' && <Typography variant="body1">Aquí puedes gestionar tus clientes.</Typography>}
        </Box>
      </Box>
    </DefaultLayout>
  );
}

export default SalesTracking;
