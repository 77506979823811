import React, { useEffect, useState } from 'react';
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

function useTotalPrice(numberOfUsers, pricePerUser, setFieldValue) {
  useEffect(() => {
    setFieldValue('totalPrice', numberOfUsers * pricePerUser);
  }, [numberOfUsers, pricePerUser, setFieldValue]);
}

function QuoteEdit({ quote, onSave, onClose }) {
  const [plans, setPlans] = useState([]);
  const { t } = useTranslation('config');

  // Obtener los planes desde el API
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/planes`);
        console.log('response:', response);

        const mappedPlans = response.data.map(plan => ({
          id: plan.plan_id,
          name: plan.name
        }));
        console.log('mappedPlans:', mappedPlans);

        setPlans(mappedPlans);
      } catch (error) {
        console.error('Error al obtener los planes:', error);
      }
    };
    fetchPlans();
  }, []);

  const initialValues = {
    bridge: quote.bridge || '',
    country: 'MEX', // Mover "country" antes de "totalPrice"
    numberOfAccessPoints: quote.numberOfAccessPoints || '',
    numberOfUsers: quote.numberOfUsers || '',
    planId: quote.planName || '',
    pricePerUser: quote.pricePerUser || '',
    propertyName: quote.propertyName || '',
    status: quote.status || 'draft',
    totalPrice: '' // Mantener orden alfabético
  };

  // Schema de validación con Yup
  const validationSchema = Yup.object().shape({
    bridge: Yup.number().required(t('quote.quoteEdit.errorBridge')).min(0, t('quote.quoteEdit.errorNe')),
    numberOfAccessPoints: Yup.number().required(t('quote.quoteEdit.errorAccessPoints')).min(1, t('quote.quoteEdit.minOne')),
    numberOfUsers: Yup.number().required(t('quote.quoteEdit.errorNumUsers')).min(1, t('quote.quoteEdit.minOne')),
    planId: Yup.number().required(t('quote.quoteEdit.errorSelectPlan')),
    pricePerUser: Yup.number().required(t('quote.quoteEdit.errorPriceXUser')).min(1, t('quote.quoteEdit.errorCero')),
    status: Yup.string().required(t('quote.quoteEdit.errorSelect')),
    ...(quote.id === undefined && { country: Yup.string().required(t('quote.quoteEdit.errorCountry')) })
  });

  console.log('quote:', quote);

  const handleSubmit = async (values, { setErrors, validateForm }) => {
    if (!quote.customer_id) {
      toast.error(t('quote.quoteEdit.alertIdCustomer'));
      return;
    }

    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      console.log('Entro a los erroressssssss');
      setErrors(errors);
      toast.error(t('quote.quoteEdit.alertFullFields'));
      return;
    }

    try {
      if (!quote.id) {
        // Crear nueva cotización
        const dataObjectCreate = {
          business_partner_id: quote.customer.business_partner_id,
          country: values.country, // Mover "country" antes de "total_price"
          number_of_access_points: values.numberOfAccessPoints,
          number_of_bridges: values.bridge,
          number_of_users: values.numberOfUsers,
          plan_id: values.planId,
          price_per_user: values.pricePerUser,
          prospect_id: quote.customer_id,
          status: values.status,
          total_price: values.totalPrice
        };

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/sales/quote`, dataObjectCreate);
        if (response.status === 201) {
          toast.success(t('quote.quoteEdit.quoteSuccessfulQuote'));
          if (onSave) onSave(); // Llama al método `onSave` para regresar automáticamente
          if (onClose) onClose();
        } else {
          toast.error(t('quote.quoteEdit.quoteErrorCreate'));
        }
      } else {
        // Actualizar cotización existente
        const dataObjectUpdate = {
          business_partner_id: quote.businessPartnerID,
          number_of_access_points: values.numberOfAccessPoints,
          number_of_bridges: values.bridge,
          number_of_users: values.numberOfUsers,
          plan_id: values.planId,
          price_per_user: values.pricePerUser,
          status: values.status,
          total_price: values.totalPrice
        };
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/sales/quote/${quote.id}`, dataObjectUpdate);
        if (response.status === 200) {
          toast.success(t('quote.quoteEdit.alertQuoteUpdate'));
        } else {
          toast.error(t('quote.quoteEdit.alertError'));
        }
        if (onSave) onSave(); // Llama al método `onSave` para regresar automáticamente
        if (onClose) onClose();
      }
    } catch (error) {
      console.error('Error al guardar la cotización:', error);
      toast.error(t('quote.quoteEdit.alertErrorSaveQuote'));
    }
  };

  return (
    <Paper
      elevation={3}
      style={{
        border: '1px solid #ddd',
        margin: '0 auto',
        maxWidth: '1500px',
        padding: '30px'
      }}
    >
      <Typography style={{ fontSize: '24px', fontWeight: 'bold' }} variant="h5">
        {quote.propertyName ? t('quote.quoteEdit.quoteEditText') : t('quote.quoteEdit.quoteCreate')}
      </Typography>
      <Typography style={{ fontSize: '10px', fontWeight: 'bold' }} variant="h5">
        {quote.id ? `QuoteID ${quote.id}` : `CustomerID ${quote.customer_id}`}
      </Typography>
      <Typography style={{ fontSize: '20px', marginBottom: '30px' }} variant="subtitle1">
        {quote.propertyName || ''}
      </Typography>
      <Divider style={{ marginBottom: '30px' }} />
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ values, errors, touched, handleChange, setFieldValue }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useTotalPrice(values.numberOfUsers, values.pricePerUser, setFieldValue);

          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  {/* Plan */}
                  <FormControl error={touched.planId && Boolean(errors.planId)} fullWidth style={{ marginBottom: '20px' }} variant="outlined">
                    <InputLabel>{t('quote.quoteEdit.plan')}</InputLabel>
                    <Field as={Select} label="Plan" name="planId" onChange={handleChange} value={values.planId}>
                      {plans.map(plan => (
                        <MenuItem key={plan.id} value={plan.id}>
                          {plan.name}
                        </MenuItem>
                      ))}
                    </Field>
                    {touched.planId && errors.planId && (
                      <Typography color="error" variant="caption">
                        {errors.planId}
                      </Typography>
                    )}
                  </FormControl>

                  {/* Número de puntos de acceso */}
                  <Field
                    as={TextField}
                    error={touched.numberOfAccessPoints && Boolean(errors.numberOfAccessPoints)}
                    fullWidth
                    helperText={touched.numberOfAccessPoints && errors.numberOfAccessPoints}
                    label={t('quote.quoteEdit.numberAccess')}
                    name="numberOfAccessPoints"
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    type="number"
                    value={values.numberOfAccessPoints}
                    variant="outlined"
                  />

                  {/* Número de usuarios */}
                  <Field
                    as={TextField}
                    error={touched.numberOfUsers && Boolean(errors.numberOfUsers)}
                    fullWidth
                    helperText={touched.numberOfUsers && errors.numberOfUsers}
                    label={t('quote.quoteEdit.numberUsers')}
                    name="numberOfUsers"
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    type="number"
                    value={values.numberOfUsers}
                    variant="outlined"
                  />

                  {/* Número de bridges */}
                  <Field
                    as={TextField}
                    error={touched.bridge && Boolean(errors.bridge)}
                    fullWidth
                    helperText={touched.bridge && errors.bridge}
                    label={t('quote.quoteEdit.numberBridge')}
                    name="bridge"
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    type="number"
                    value={values.bridge}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  {/* Precio por usuario */}
                  <Field
                    as={TextField}
                    error={touched.pricePerUser && Boolean(errors.pricePerUser)}
                    fullWidth
                    helperText={touched.pricePerUser && errors.pricePerUser}
                    label={t('quote.quoteEdit.priceUsers')}
                    name="pricePerUser"
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    type="number"
                    value={values.pricePerUser}
                    variant="outlined"
                  />

                  {/* Total (sólo lectura) */}
                  <Field
                    as={TextField}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label={t('quote.quoteEdit.total')}
                    name="totalPrice"
                    style={{ marginBottom: '20px' }}
                    type="number"
                    value={values.totalPrice}
                    variant="outlined"
                  />

                  {/* Estatus */}
                  <FormControl error={touched.status && Boolean(errors.status)} fullWidth style={{ marginBottom: '20px' }} variant="outlined">
                    <InputLabel>{t('quote.quoteEdit.status')}</InputLabel>
                    <Field as={Select} label="Status" name="status" onChange={handleChange} value={values.status}>
                      <MenuItem value="draft">draft</MenuItem>
                      <MenuItem value="ready">ready</MenuItem>
                      <MenuItem value="sent">sent</MenuItem>
                    </Field>

                    {touched.status && errors.status && (
                      <Typography color="error" variant="caption">
                        {errors.status}
                      </Typography>
                    )}
                  </FormControl>

                  {/* Campo País - solo para creación */}
                  {!quote.id && (
                    <Grid item md={12} xs={12}>
                      <FormControl error={touched.country && Boolean(errors.country)} fullWidth style={{ marginBottom: '20px' }} variant="outlined">
                        <InputLabel>{t('quote.quoteEdit.country')}</InputLabel>
                        <Field as={Select} label={t('quote.quoteEdit.country')} name="country" onChange={handleChange} value={values.country}>
                          <MenuItem value="MEX">Mexico</MenuItem>
                        </Field>
                        {touched.country && errors.country && (
                          <Typography color="error" variant="caption">
                            {errors.country}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  <div style={{ marginTop: '30px' }}>
                    <Button color="primary" style={{ fontSize: '16px' }} type="submit" variant="contained">
                      {quote && quote.id ? t('quote.quoteEdit.update') : t('quote.quoteEdit.createQuote')}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
}

export default QuoteEdit;
